import { Column } from '@material-table/core'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { findDefaultFilterComponent } from 'utils/admin/adminFormUtils'
import AdminTableTooltipClip from '../common/AdminTableTooltipClip'
import AdminTableTooltipTitle from '../common/AdminTableTooltipTitle'
import React from 'react'
import createDOMPurify from 'dompurify'
import { FeaturedResult } from 'models/FeaturedResult'
import { capitalizeFirstLetter, stripHtml } from 'utils/string'
import { dateFormatOptions } from 'constants/constants'
import { Rating } from '@mui/material'
import { Add } from '@mui/icons-material'
import { getVerticalByKey } from 'constants/supportedVerticals'

const DOMPurify = createDOMPurify(window)

/**
 * Generate featured results columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const featuredResultColumns = (): Column<FeaturedResult>[] => {
  const classes = getStylesAdminSettings()
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'string',
      filtering: false,
      width: 40,
      cellStyle: {
        maxWidth: 40,
        width: 40
      },
      headerStyle: {
        maxWidth: 40,
        width: 40
      },
      customSort: (a: FeaturedResult, b: FeaturedResult) =>
        parseInt(a.id) - parseInt(b.id)
    },
    {
      title: 'Title',
      field: 'BestBetTitle',
      type: 'string',
      filtering: false,
      render: (rowData: FeaturedResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
            rowData.BestBetTitle ? DOMPurify.sanitize(rowData.BestBetTitle) : ''
          }`}
          maxLine={4}
        />
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      }
    },
    {
      title: 'Description',
      field: 'BestBetDescription',
      type: 'string',
      render: (rowData: FeaturedResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={
            rowData.BestBetDescription
              ? DOMPurify.sanitize(stripHtml(rowData.BestBetDescription))
              : ''
          }
          maxLine={2}
        />
      ),
      filtering: false
    },
    {
      title: 'Url',
      field: 'BestBetUrl',
      type: 'string',
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      width: 80,
      render: (rowData: FeaturedResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.BestBetUrl}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Start',
      field: 'BestBetStartDate',
      type: 'date',
      filtering: false,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      width: 80,
      render: (rowData: FeaturedResult) => {
        if (rowData.BestBetStartDate) {
          let parsedDate = new Date(rowData.BestBetStartDate)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        return ''
      }
    },
    {
      title: 'End',
      field: 'BestBetEndDate',
      type: 'date',
      filtering: false,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      width: 80,
      render: (rowData: FeaturedResult) => {
        if (rowData.BestBetEndDate) {
          let parsedDate = new Date(rowData.BestBetEndDate)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        return ''
      }
    },
    {
      title: 'Site Url',
      field: 'BestBetSiteUrl',
      type: 'string',
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      width: 100,
      render: (rowData: FeaturedResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.BestBetSiteUrl}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Rank',
      field: 'BestBetRank',
      type: 'numeric',
      align: 'left',
      cellStyle: {
        minWidth: 120
      },
      headerStyle: {
        minWidth: 120
      },
      width: 120,
      render: (rowData: FeaturedResult) => (
        <div style={{ display: 'flex' }}>
          <Rating value={rowData.BestBetRank} readOnly size="small" />
          {(rowData.BestBetRank ? rowData.BestBetRank : 0) > 5 && (
            <Add style={{ height: '18px' }} />
          )}
        </div>
      ),
      filtering: false
    },
    {
      title: 'Sources',
      field: 'BestBetDataSources',
      type: 'string',
      render: (rowData: FeaturedResult) => {
        const isAllSet = !!rowData.BestBetDataSources.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showSourcesList = rowData.BestBetDataSources.filter(
          (item: string) => item.toLocaleLowerCase() !== 'all'
        ).slice(0, isAllSet ? 2 : 3)
        showSourcesList = showSourcesList.map((ds: string) => {
          return ds
        })
        let preFix = ''

        if (isAllSet) {
          showSourcesList = ['All', ...showSourcesList]
          preFix = '!= '
        }

        return (
          <div className={classes.chipContainer}>
            {rowData.BestBetDataSources
              ? showSourcesList.map((source: string, index: number) => (
                  <AdminTableTooltipClip
                    label={
                      source.toLocaleLowerCase() !== 'all'
                        ? preFix + getVerticalByKey(source)
                        : getVerticalByKey(source)
                    }
                    variant="outlined"
                    maxWidth={90}
                    key={index}
                  />
                ))
              : ''}
          </div>
        )
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Countries',
      field: 'BestBetCountry',
      type: 'string',
      render: (rowData: FeaturedResult) => {
        const isAllSet = !!rowData.BestBetCountry.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showCountriesList = rowData.BestBetCountry.filter(
          (item: string) => item.toLocaleLowerCase() !== 'all'
        ).slice(0, isAllSet ? 2 : 3)
        let preFix = ''

        if (isAllSet) {
          showCountriesList = ['All', ...showCountriesList]
          preFix = '!= '
        }

        if (!rowData.BestBetCountry || rowData.BestBetCountry.length === 0)
          showCountriesList.push('All')

        return (
          <div className={classes.chipContainer}>
            {rowData.BestBetCountry
              ? showCountriesList.map((country: string, index: number) => (
                  <AdminTableTooltipClip
                    label={
                      country.toLocaleLowerCase() !== 'all'
                        ? preFix + capitalizeFirstLetter(country)
                        : capitalizeFirstLetter(country)
                    }
                    variant="outlined"
                    maxWidth={90}
                    key={index}
                  />
                ))
              : ''}
          </div>
        )
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    /* {
      title: 'Cities',
      field: 'BestBetCity',
      type: 'string',
      render: (rowData: FeaturedResult) => {
        const isAllSet = !!rowData.BestBetCity.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showCitiesList = rowData.BestBetCity.filter(
          (item: string) => item.toLocaleLowerCase() !== 'all'
        ).slice(0, isAllSet ? 2 : 3)
        let preFix = ''

        if (isAllSet) {
          showCitiesList = ['All', ...showCitiesList]
          preFix = '!= '
        }

        return (
          <div className={classes.chipContainer}>
            {rowData.BestBetCity
              ? showCitiesList.map((city: string) => (
                  <AdminTableTooltipClip
                    label={
                      city.toLocaleLowerCase() !== 'all'
                        ? preFix + capitalizeFirstLetter(city)
                        : capitalizeFirstLetter(city)
                    }
                    variant="outlined"
                    maxWidth={90}
                  />
                ))
              : ''}
          </div>
        )
      },
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      }
    }, */
    {
      title: 'Functions',
      field: 'BestBetFunction',
      type: 'string',
      render: (rowData: FeaturedResult) => {
        const isAllSet = !!rowData.BestBetFunction.find(
          (item: string) => item.toLocaleLowerCase() === 'all'
        )

        let showFunctionsList = rowData.BestBetFunction.filter(
          (item: string) => item.toLocaleLowerCase() !== 'all'
        ).slice(0, isAllSet ? 2 : 3)
        let preFix = ''

        if (isAllSet) {
          showFunctionsList = ['All', ...showFunctionsList]
          preFix = '!= '
        }

        if (!rowData.BestBetFunction || rowData.BestBetFunction.length === 0)
          showFunctionsList.push('All')

        return (
          <div className={classes.chipContainer}>
            {rowData.BestBetFunction
              ? showFunctionsList.map((fn: string, index: number) => (
                  <AdminTableTooltipClip
                    label={
                      fn.toLocaleLowerCase() !== 'all'
                        ? preFix + capitalizeFirstLetter(fn)
                        : capitalizeFirstLetter(fn)
                    }
                    variant="outlined"
                    maxWidth={90}
                    key={index}
                  />
                ))
              : ''}
          </div>
        )
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Search Terms',
      field: 'BestBetKeywords',
      type: 'string',
      render: (rowData: FeaturedResult) => (
        <div className={classes.chipContainer}>
          {rowData.BestBetKeywords
            ? rowData.BestBetKeywords.slice(0, 3).map(
                (searchTerm: string, index: number) => (
                  <AdminTableTooltipClip
                    label={searchTerm}
                    variant="outlined"
                    maxWidth={90}
                    key={index}
                  />
                )
              )
            : ''}
        </div>
      ),
      filtering: false,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      width: 120
    },
    {
      title: 'Match Type',
      field: 'BestBetMatchType',
      type: 'string',
      render: (rowData: FeaturedResult) => (
        <AdminTableTooltipClip
          color="primary"
          label={
            rowData.BestBetMatchType ? rowData.BestBetMatchType : 'Contains'
          }
          variant={'outlined'}
          maxWidth={90}
        />
      ),
      width: 90,
      cellStyle: {
        maxWidth: 90
      },
      headerStyle: {
        maxWidth: 90
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Scope',
      field: 'BestBetScope',
      type: 'string',
      render: (rowData: FeaturedResult) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.BestBetScope ? rowData.BestBetScope : 'All'}
          variant={'outlined'}
          maxWidth={80}
        />
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      customFilterAndSearch: (value: string, rowData: FeaturedResult) => {
        if (!value) return true

        if (!rowData.BestBetScope && 'all'.startsWith(value.toLowerCase())) {
          return true
        } else {
          return rowData.BestBetScope
            ? rowData.BestBetScope.toLowerCase().startsWith(value.toLowerCase())
            : false
        }
      },
      filterComponent: findDefaultFilterComponent
    }
  ]
}
