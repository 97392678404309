import React from 'react'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import { Column } from '@material-table/core'
import createDOMPurify from 'dompurify'
import {
  supportedLanguages,
  SupportedLanguage
} from 'constants/supportedLanguages'
import { stripHtml } from 'utils/string'
import AdminTableTooltipClip from 'components/admin/common/AdminTableTooltipClip'
import AdminTableTooltipTitle from 'components/admin/common/AdminTableTooltipTitle'
import { dateFormatOptions } from 'constants/constants'
import { findDefaultFilterComponent } from 'utils/admin/adminFormUtils'
import { PopupResult } from 'models/PopupResult'
import { getVerticalByKey } from 'constants/supportedVerticals'
import * as Config from '../../../config'

const DOMPurify = createDOMPurify(window)

/**
 * Generate popup columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const popupColumns = (): Column<PopupResult>[] => {
  const classes = getStylesAdminSettings()
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'string',
      filtering: false,
      width: 40,
      cellStyle: {
        maxWidth: 40,
        width: 40
      },
      headerStyle: {
        maxWidth: 40,
        width: 40
      },
      customSort: (a: PopupResult, b: PopupResult) =>
        parseInt(a.id) - parseInt(b.id)
    },
    {
      title: 'Language',
      field: 'language',
      type: 'string',
      filtering: false,
      width: 80,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      render: (rowData: PopupResult) => {
        let allLanguages = true

        const popupChilds = rowData.childs
        supportedLanguages.forEach((supportedLanguage: SupportedLanguage) => {
          if (rowData.language !== supportedLanguage.locale) {
            if (
              !popupChilds ||
              !popupChilds.find(
                (popupChild: PopupResult) =>
                  popupChild.language === supportedLanguage.locale
              )
            ) {
              allLanguages = false
            }
          }
        })

        return (
          <div className={classes.chipContainer}>
            {allLanguages ? (
              <AdminTableTooltipClip size="small" label={'All'} maxWidth={90} />
            ) : (
              <>
                <AdminTableTooltipClip
                  size="small"
                  label={rowData.language}
                  maxWidth={90}
                />
                {popupChilds && popupChilds.length > 0
                  ? popupChilds.map((adChild: PopupResult, index: number) => (
                      <AdminTableTooltipClip
                        size="small"
                        label={adChild.language}
                        maxWidth={90}
                        key={index}
                      />
                    ))
                  : ''}
              </>
            )}
          </div>
        )
      }
    },
    {
      title: 'Title',
      field: 'title',
      type: 'string',
      filtering: false,
      render: (rowData: PopupResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
            rowData.title ? DOMPurify.sanitize(rowData.title) : ''
          }`}
          maxLine={4}
        />
      ),
      width: 140,
      cellStyle: {
        maxWidth: 140
      },
      headerStyle: {
        maxWidth: 140
      }
    },
    {
      title: 'Content',
      field: 'content',
      type: 'string',
      render: (rowData: PopupResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="letters"
          unsafeHTML={
            rowData.content
              ? DOMPurify.sanitize(stripHtml(rowData.content))
              : ''
          }
          maxLine={2}
        />
      ),
      filtering: false
    },
    {
      title: 'Button Link',
      field: 'button_link',
      type: 'string',
      width: 140,
      cellStyle: {
        maxWidth: 140
      },
      headerStyle: {
        maxWidth: 140
      },
      render: (rowData: PopupResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.button_link ? rowData.button_link : ''}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Button Text',
      field: 'button_text',
      type: 'string',
      filtering: false,
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      render: (rowData: PopupResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.button_text ? rowData.button_text : ''}
          maxLine={1}
        />
      )
    },
    {
      title: 'Display',
      field: 'display',
      type: 'string',
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent,
      render: (rowData: PopupResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.display ? rowData.display : ''}
          maxLine={1}
        />
      )
    },
    {
      title: 'Start',
      field: 'dateStart',
      type: 'date',
      filtering: false,
      width: 80,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      render: (rowData: PopupResult) => {
        if (rowData.dateStart) {
          let parsedDate = new Date(rowData.dateStart)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        return ''
      }
    },
    {
      title: 'End',
      field: 'dateEnd',
      type: 'date',
      filtering: false,
      width: 80,
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      render: (rowData: PopupResult) => {
        if (rowData.dateEnd) {
          let parsedDate = new Date(rowData.dateEnd)
          const timeOffsetInMS = parsedDate.getTimezoneOffset() * 60000
          parsedDate.setTime(parsedDate.getTime() - timeOffsetInMS)

          return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(
            parsedDate
          )
        }

        return ''
      }
    },
    {
      title: 'Interval',
      field: 'intervalHours',
      type: 'string',
      filtering: false,
      width: 60,
      cellStyle: {
        maxWidth: 60
      },
      headerStyle: {
        maxWidth: 60
      },
      render: (rowData: PopupResult) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.intervalHours ? rowData.intervalHours.toString() : ''}
          maxLine={1}
        />
      )
    },
    {
      title: 'Image',
      field: 'image',
      type: 'string',
      render: (rowData: PopupResult) =>
        rowData.image ? (
          <img
            src={
              rowData.image.startsWith('/externalContent/adsmedia/')
                ? `${Config.APIM_BASE_URL.replace('/apim/', '')}${
                    rowData.image
                  }`
                : rowData.image
            }
            alt="Popup url"
            style={{
              width: 80,
              height: 40
            }}
          />
        ) : (
          ''
        ),
      filtering: false,
      width: 120,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      }
    },

    {
      title: 'Sources',
      field: 'sources',
      type: 'string',
      render: (rowData: PopupResult) => (
        <div className={classes.chipContainer}>
          {rowData.sources
            ? rowData.sources
                .slice(0, 3)
                .map((source: string, index: number) => (
                  <AdminTableTooltipClip
                    label={getVerticalByKey(source)}
                    variant="outlined"
                    maxWidth={90}
                    key={index}
                  />
                ))
            : ''}
        </div>
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Countries',
      field: 'countries',
      type: 'string',
      render: (rowData: PopupResult) => (
        <div className={classes.chipContainer}>
          {rowData.countries
            ? rowData.countries
                .slice(0, 3)
                .map((country: string, index: number) => (
                  <AdminTableTooltipClip
                    label={country}
                    variant="outlined"
                    maxWidth={90}
                    key={index}
                  />
                ))
            : ''}
        </div>
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Functions',
      field: 'functions',
      type: 'string',
      render: (rowData: PopupResult) => (
        <div className={classes.chipContainer}>
          {rowData.functions
            ? rowData.functions
                .slice(0, 3)
                .map((fn: string, index: number) => (
                  <AdminTableTooltipClip
                    label={fn}
                    variant="outlined"
                    maxWidth={90}
                    key={index}
                  />
                ))
            : ''}
        </div>
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      filterComponent: findDefaultFilterComponent
    },
    {
      title: 'Scope',
      field: 'scope',
      type: 'string',
      render: (rowData: PopupResult) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.scope ? rowData.scope : 'All'}
          variant={'outlined'}
          maxWidth={80}
        />
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      customFilterAndSearch: (value: string, rowData: PopupResult) => {
        if (!value) return true

        if (!rowData.scope && 'all'.startsWith(value.toLowerCase())) {
          return true
        } else {
          return rowData.scope
            ? rowData.scope.toLowerCase().startsWith(value.toLowerCase())
            : false
        }
      },
      filterComponent: findDefaultFilterComponent
    }
  ]
}
