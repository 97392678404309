import { IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  checkIsFeaturedResultValid,
  convertToArray,
  editFormErrorKeys,
  renderSelect
} from 'utils/admin/adminFormUtils'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import createDOMPurify from 'dompurify'
import {
  SupportedFunction,
  supportedFunctions
} from 'constants/supportedFunctions'
import { countriesISO } from 'constants/countriesISO'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { createDefaultDate } from 'utils/date'
import { filterSearchTermInput } from 'utils/admin/adminContentQuality'
import { capitalizeFirstLetter } from 'utils/string'
import { FeaturedResult } from 'models/FeaturedResult'
import { ISelfServiceRequest, RequestStatus } from 'models/SelfServiceRequest'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import {
  ISupportedVertical,
  supportedVerticals
} from 'constants/supportedVerticals'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

export interface IEditFormFeaturedResultsProps {
  item: ISelfServiceRequest
  isNewItem: boolean
  setIsValid: (isValid: boolean) => void
}

export default function EditFormFeaturedResultsRequest(
  props: IEditFormFeaturedResultsProps
): JSX.Element {
  const { item, isNewItem, setIsValid } = props

  const rowData: FeaturedResult = item.itemData as FeaturedResult
  const DOMPurify = createDOMPurify(window)

  // timezone into start date
  const parsedStartDate = rowData.BestBetStartDate
    ? new Date(rowData.BestBetStartDate)
    : null
  let startDateString
  if (parsedStartDate) {
    const timeOffsetInMS = parsedStartDate.getTimezoneOffset() * 60000
    parsedStartDate.setTime(parsedStartDate.getTime() - timeOffsetInMS)
    startDateString = parsedStartDate.toISOString()
  }
  // timezone into end date
  const parsedEndDate = rowData.BestBetEndDate
    ? new Date(rowData.BestBetEndDate)
    : null
  let endDateString
  if (parsedEndDate) {
    const timeOffsetInMS = parsedEndDate.getTimezoneOffset() * 60000
    parsedEndDate.setTime(parsedEndDate.getTime() - timeOffsetInMS)
    endDateString = parsedEndDate.toISOString()
  }

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    startDateString ? new Date(startDateString) : null
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    endDateString ? new Date(endDateString) : null
  )

  const [sources, setSources] = useState(
    rowData.BestBetDataSources.map((dsKey: string) => {
      return {
        key: capitalizeFirstLetter(dsKey),
        name: capitalizeFirstLetter(dsKey)
      }
    })
  )
  const [countries, setCountries] = useState(
    rowData.BestBetCountry && rowData.BestBetCountry.length > 0
      ? rowData.BestBetCountry
      : ['All']
  )
  const [functions, setFunctions] = useState(
    rowData.BestBetFunction && rowData.BestBetFunction.length > 0
      ? rowData.BestBetFunction
      : ['All']
  )
  const [matchType, setMatchType] = useState(
    rowData.BestBetMatchType ? rowData.BestBetMatchType : 'Contains'
  )
  const [scope, setScope] = useState(
    rowData && rowData.BestBetScope ? rowData.BestBetScope : 'All'
  )
  const [availableSources, setAvailableSources] = useState<
    ISupportedVertical[]
  >([])
  const classes = getStylesAdminForm()
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])

  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [countryList, setCountryList] = useState<string[]>([])
  const [functionList, setFunctionList] = useState<string[]>([])
  const [rank, setRank] = useState(
    rowData && rowData.BestBetRank ? rowData.BestBetRank : 0
  )

  const hasChanges =
    item.status === RequestStatus.Submitted &&
    item.historyData?.data !== undefined

  const [showHistory, setShowHistory] = useState(hasChanges)

  const validateItem = (): void => {
    let errorList: IErrorListObject[] = []

    errorList = [...errorList, ...checkIsFeaturedResultValid(rowData, true)]

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  useEffect(() => {
    if (!endDate && isNewItem) {
      const dateString = createDefaultDate()
      setEndDate(new Date(dateString))
      rowData.BestBetEndDate = dateString
    }

    if (!startDate && isNewItem) {
      const dateString = new Date().toISOString()
      setStartDate(new Date(dateString))
      rowData.BestBetStartDate = dateString
    }

    validateItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateAvailableSources()

    if (scope === 'KPMGFind' && rowData.BestBetSiteUrl) {
      rowData.BestBetSiteUrl = ''
      validateItem()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const updateAvailableSources = () => {
    let functionList = supportedFunctions.map(
      (item: SupportedFunction) => item.name
    )
    let countryList = countriesISO.map((item) => item.Name)

    let sourcelist = supportedVerticals

    sourcelist = [{ key: 'All', name: 'All' }, ...sourcelist]
    countryList = ['All', ...countryList]
    functionList = ['All', ...functionList]

    setAvailableSources(sourcelist)
    setFunctionList(functionList)
    setCountryList(countryList)
  }

  const getHistoryData = (key: string): string | undefined => {
    if (
      !item.historyData ||
      item.historyData.isNewItem ||
      !item.historyData.data
    ) {
      return undefined
    }

    const historyData = item.historyData.data as any

    return historyData[key]
  }

  const getHistoryDate = (key: string): Date => {
    const historyData = item.historyData?.data as any

    return new Date(historyData[key])
  }

  return (
    <>
      {hasChanges && (
        <div className={classes.hideHistoryButtonContainer}>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setShowHistory(!showHistory)
            }}
            className={classes.hideHistoryButton}
          >
            {showHistory ? (
              <>
                <div style={{ marginRight: '5px' }}>Hide History</div>
                <ArrowForwardIosIcon fontSize="inherit" />
              </>
            ) : (
              <>
                <ArrowBackIosIcon fontSize="inherit" />
                <div style={{ marginLeft: '5px' }}>Show History</div>
              </>
            )}
          </IconButton>
        </div>
      )}
      <div className={classes.historyContainer}>
        <TextField
          variant="standard"
          id="title-required"
          className={classes.tab_item_request}
          label="Title *"
          defaultValue={rowData.BestBetTitle}
          onChange={(event) => {
            rowData.BestBetTitle = event.target.value
              ? DOMPurify.sanitize(event.target.value, {
                  USE_PROFILES: { html: false }
                })
              : ''

            validateItem()
          }}
          onBlur={() => {
            setCurrentFormState(new Date().getTime())
          }}
          error={getError(editFormErrorKeys.title).error}
          helperText={getError(editFormErrorKeys.title).helperText}
          InputLabelProps={{
            shrink: true
          }}
          key={`title_${currentFormState}`}
        />
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetTitle') !== undefined ? (
            <TextField
              variant="standard"
              id="title-required"
              className={classes.tab_item_history}
              defaultValue={getHistoryData('BestBetTitle')}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
            />
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        <TextField
          variant="standard"
          className={classes.tab_item_request}
          multiline
          required
          id="text"
          label="Description"
          defaultValue={rowData.BestBetDescription}
          rows={5}
          onChange={(event) => {
            rowData.BestBetDescription = event.target.value
              ? DOMPurify.sanitize(event.target.value, {
                  USE_PROFILES: { html: false }
                })
              : event.target.value

            validateItem()
          }}
          onBlur={() => {
            setCurrentFormState(new Date().getTime())
          }}
          error={getError(editFormErrorKeys.description).error}
          helperText={getError(editFormErrorKeys.description).helperText}
          InputLabelProps={{
            shrink: true
          }}
          key={`desc_${currentFormState}`}
        />
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetDescription') !== undefined ? (
            <TextField
              variant="standard"
              className={classes.tab_item_history}
              multiline
              required
              id="text"
              defaultValue={getHistoryData('BestBetDescription')}
              rows={5}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
            />
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        <TextField
          variant="standard"
          id="url"
          label="Url"
          className={classes.tab_item_request}
          defaultValue={rowData.BestBetUrl}
          required
          onChange={(event) => {
            validateItem()
            rowData.BestBetUrl = event.target.value
              ? event.target.value.replace(/ /g, '%20')
              : event.target.value
          }}
          onBlur={(event) => {
            validateItem()
          }}
          InputLabelProps={{
            shrink: true
          }}
          error={
            getError(editFormErrorKeys.link).error ||
            getError(editFormErrorKeys.url).error
          }
          helperText={
            getError(editFormErrorKeys.link).helperText ||
            getError(editFormErrorKeys.url).helperText
          }
        />
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetUrl') !== undefined ? (
            <TextField
              variant="standard"
              className={classes.tab_item_history}
              id="historyUrl"
              defaultValue={getHistoryData('BestBetUrl')}
              InputLabelProps={{
                shrink: true
              }}
              disabled={true}
            />
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {' '}
          <DatePicker
            label={'Start *'}
            format="MM/dd/yyyy"
            value={startDate || null}
            onChange={(date: Date | null, context) => {
              let dateString = null
              if (date && date.toString() !== 'Invalid Date') {
                const day = date.getDate()
                const month = date.getMonth() + 1
                const year = date.getFullYear()
                dateString =
                  year.toString() +
                  '-' +
                  month.toString().padStart(2, '0') +
                  '-' +
                  day.toString().padStart(2, '0') +
                  'T00:00:00.000Z'
              }

              rowData.BestBetStartDate = dateString
              setStartDate(date ? date : null)
              validateItem()
            }}
            onYearChange={() => {
              const popperRoot = document.querySelectorAll(
                '[class*=MuiPickersPopper-root'
              )
              if (popperRoot.length > 0) {
                const transformValue = (popperRoot[0] as HTMLElement).style
                  .transform
                setTimeout(() => {
                  ;(popperRoot[0] as HTMLElement).style.transform =
                    transformValue
                }, 10)
              }
            }}
            onClose={() => {
              const popperRoot = document.querySelectorAll(
                '[class*=MuiPickersPopper-root'
              )
              if (popperRoot.length > 0) {
                ;(popperRoot[0] as HTMLElement).style.display = 'none'
              }
            }}
            slotProps={{
              textField: {
                variant: 'standard',
                className: classes.datePicker
              }
            }}
            slots={{
              textField: (textFieldProps) => (
                <TextField
                  {...textFieldProps}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={getError(editFormErrorKeys.startDate).error}
                  helperText={getError(editFormErrorKeys.startDate).helperText}
                />
              )
            }}
            className={classes.tab_item_request}
          />
        </LocalizationProvider>
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetStartDate') !== undefined ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/dd/yyyy"
                value={getHistoryDate('BestBetStartDate') || null}
                onChange={(date: Date | null) => {}}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    className: classes.datePicker
                  }
                }}
                slots={{
                  textField: (textFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )
                }}
                disabled={true}
                className={classes.tab_item_history}
              />
            </LocalizationProvider>
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'End *'}
            format="MM/dd/yyyy"
            value={endDate || null}
            onChange={(date: Date | null) => {
              let dateString = null
              if (date && date.toString() !== 'Invalid Date') {
                const day = date.getDate()
                const month = date.getMonth() + 1
                const year = date.getFullYear()
                dateString =
                  year.toString() +
                  '-' +
                  month.toString().padStart(2, '0') +
                  '-' +
                  day.toString().padStart(2, '0') +
                  'T23:59:59.000Z'
              }

              rowData.BestBetEndDate = dateString
              setEndDate(date ? date : null)
              validateItem()
            }}
            onYearChange={() => {
              const popperRoot = document.querySelectorAll(
                '[class*=MuiPickersPopper-root'
              )
              if (popperRoot.length > 0) {
                const transformValue = (popperRoot[0] as HTMLElement).style
                  .transform
                setTimeout(() => {
                  ;(popperRoot[0] as HTMLElement).style.transform =
                    transformValue
                }, 10)
              }
            }}
            onClose={() => {
              const popperRoot = document.querySelectorAll(
                '[class*=MuiPickersPopper-root'
              )
              if (popperRoot.length > 0) {
                ;(popperRoot[0] as HTMLElement).style.display = 'none'
              }
            }}
            slotProps={{
              textField: {
                variant: 'standard',
                className: classes.datePicker
              }
            }}
            slots={{
              textField: (textFieldProps) => (
                <TextField
                  {...textFieldProps}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={getError(editFormErrorKeys.endDate).error}
                  helperText={getError(editFormErrorKeys.endDate).helperText}
                />
              )
            }}
            className={classes.tab_item_request}
          />
        </LocalizationProvider>
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetEndDate') !== undefined ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/dd/yyyy"
                value={getHistoryDate('BestBetEndDate') || null}
                onChange={(date: Date | null) => {}}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    className: classes.datePicker
                  }
                }}
                slots={{
                  textField: (textFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )
                }}
                disabled={true}
                className={classes.tab_item_history}
              />
            </LocalizationProvider>
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      {scope !== 'KPMGFind' && (
        <div className={classes.historyContainer}>
          <TextField
            variant="standard"
            id="site_url"
            label="Site Url"
            defaultValue={rowData.BestBetSiteUrl}
            onChange={(event) => {
              validateItem()
              rowData.BestBetSiteUrl = event.target.value
                ? event.target.value.replace(/ /g, '%20')
                : event.target.value
            }}
            onBlur={(event) => {
              validateItem()
            }}
            InputLabelProps={{
              shrink: true
            }}
            error={getError(editFormErrorKeys.site_url).error}
            helperText={getError(editFormErrorKeys.site_url).helperText}
            className={classes.tab_item_request}
          />
          {hasChanges &&
            showHistory &&
            (getHistoryData('BestBetSiteUrl') !== undefined ? (
              <TextField
                variant="standard"
                id="site_url"
                defaultValue={getHistoryData('BestBetSiteUrl')}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={true}
                className={classes.tab_item_history}
              />
            ) : (
              <div className={classes.tab_item_history}></div>
            ))}
        </div>
      )}
      <div className={classes.historyContainer}>
        <TextField
          variant="standard"
          id="rank"
          label="Rank"
          value={rank}
          onChange={(event) => {
            setRank(parseInt(event.target.value))
            rowData.BestBetRank = parseInt(event.target.value)
            validateItem()
          }}
          type="number"
          error={getError(editFormErrorKeys.rank).error}
          helperText={getError(editFormErrorKeys.rank).helperText}
          InputLabelProps={{
            shrink: true
          }}
          className={classes.tab_item_request}
        />
        {hasChanges && showHistory && (
          <div className={classes.tab_item_history}></div>
        )}
      </div>
      <div className={classes.historyContainer}>
        {renderSelect(
          scope,
          setScope,
          ['OI', 'KPMGFind', 'All'],
          false,
          'Scope',
          'BestBetScope',
          rowData,
          isNewItem,
          validateItem,
          undefined,
          undefined,
          undefined,
          classes.tab_item_request
        )}
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetScope') !== undefined ? (
            <>
              {renderSelect(
                getHistoryData('BestBetScope'),
                () => {},
                ['OI', 'KPMGFind', 'All'],
                false,
                'Scope',
                'BestBetScope',
                rowData,
                isNewItem,
                () => {},
                undefined,
                undefined,
                true,
                classes.tab_item_history_select
              )}
            </>
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        {renderSelect(
          sources,
          setSources,
          availableSources,
          true,
          'Sources *',
          'BestBetDataSources',
          rowData,
          isNewItem,
          validateItem,
          false,
          getError(editFormErrorKeys.source),
          undefined,
          classes.tab_item_request
        )}
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetDataSources') !== undefined ? (
            <>
              {renderSelect(
                getHistoryData('BestBetDataSources'),
                () => {},
                availableSources,
                true,
                'Sources *',
                'BestBetDataSources',
                rowData,
                isNewItem,
                () => {},
                false,
                undefined,
                true,
                classes.tab_item_history_select
              )}
            </>
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        {renderSelect(
          countries,
          setCountries,
          countryList,
          true,
          'Countries',
          'BestBetCountry',
          rowData,
          isNewItem,
          validateItem,
          undefined,
          undefined,
          undefined,
          classes.tab_item_request
        )}
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetCountry') !== undefined ? (
            <>
              {renderSelect(
                getHistoryData('BestBetCountry'),
                () => {},
                countryList,
                true,
                'Countries',
                'BestBetCountry',
                rowData,
                isNewItem,
                () => {},
                undefined,
                undefined,
                true,
                classes.tab_item_history_select
              )}
            </>
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        {renderSelect(
          functions,
          setFunctions,
          functionList,
          true,
          'Functions',
          'BestBetFunction',
          rowData,
          isNewItem,
          validateItem,
          true,
          undefined,
          undefined,
          classes.tab_item_request
        )}
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetFunction') !== undefined ? (
            <>
              {renderSelect(
                getHistoryData('BestBetFunction'),
                () => {},
                functionList,
                true,
                'Functions',
                'BestBetFunction',
                rowData,
                isNewItem,
                () => {},
                undefined,
                undefined,
                true,
                classes.tab_item_history_select
              )}
            </>
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        {renderSelect(
          matchType,
          setMatchType,
          ['Contains', 'Exact'],
          false,
          'Match Type',
          'BestBetMatchType',
          rowData,
          isNewItem,
          validateItem,
          true,
          undefined,
          undefined,
          classes.tab_item_request
        )}
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetMatchType') !== undefined ? (
            <>
              {renderSelect(
                getHistoryData('BestBetMatchType'),
                () => {},
                ['Contains', 'Exact'],
                false,
                'Match Type',
                'BestBetMatchType',
                rowData,
                isNewItem,
                () => {},
                true,
                undefined,
                true,
                classes.tab_item_history_select
              )}
            </>
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
      <div className={classes.historyContainer}>
        <TextField
          variant="standard"
          multiline
          id="search_terms"
          label="Search terms (provide a comma separated list) *"
          required
          defaultValue={rowData.BestBetKeywords}
          rows={2}
          onChange={(event) => {
            event.target.value = filterSearchTermInput(event.target.value)
            rowData.BestBetKeywords = event.target.value
              ? convertToArray(DOMPurify.sanitize(event.target.value))
              : []

            validateItem()
          }}
          InputLabelProps={{
            shrink: true
          }}
          error={getError(editFormErrorKeys.searchTerms).error}
          helperText={getError(editFormErrorKeys.searchTerms).helperText}
          className={classes.tab_item_request}
        />
        {hasChanges &&
          showHistory &&
          (getHistoryData('BestBetKeywords') !== undefined ? (
            <TextField
              variant="standard"
              multiline
              id="search_terms"
              required
              defaultValue={getHistoryData('BestBetKeywords')}
              rows={2}
              InputLabelProps={{
                shrink: true
              }}
              disabled={true}
              className={classes.tab_item_history}
            />
          ) : (
            <div className={classes.tab_item_history}></div>
          ))}
      </div>
    </>
  )
}
