import {
  Button,
  InputAdornment,
  MobileStepper,
  TextField,
  Tooltip
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  checkIsFeaturedResultFormStepValid,
  editFormErrorKeys,
  renderSelect,
  validateUrlString
} from 'utils/admin/adminFormUtils'
import { getStylesRequestForm } from 'styles/requests/requestForm'
import createDOMPurify from 'dompurify'
import {
  SupportedFunction,
  supportedFunctions
} from 'constants/supportedFunctions'
import { countriesISO } from 'constants/countriesISO'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { createDefaultDate } from 'utils/date'
import { capitalizeFirstLetter } from 'utils/string'
import { FeaturedResult } from 'models/FeaturedResult'
import {
  ISelfServiceRequest,
  RequestComment,
  RequestStatus
} from 'models/SelfServiceRequest'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import {
  ISupportedVertical,
  supportedVerticals
} from 'constants/supportedVerticals'
import DefaultFormField from 'components/contents/common/DefaultFormField'
import FeaturedResultPreview from 'components/contents/resultpages/FeaturedResultPreview'
import Chip from '@mui/material/Chip'
import AddCircle from '@mui/icons-material/AddCircle'
import { filterSearchTermInput } from 'utils/admin/adminContentQuality'
import { IFormStep, IKeyWordChipData } from 'models/FormModels'
import { FormattedMessage, useIntl } from 'react-intl'
import { updateComments } from 'utils/admin/selfServiceUtils'
import EditFormCommentDialog from './EditFormCommentDialog'

export interface IEditFormFeaturedResultStepsProps {
  item: ISelfServiceRequest
  isNewItem: boolean
  saveItem: (
    newStatus?: RequestStatus,
    newComment?: RequestComment | null,
    orgItem?: FeaturedResult,
    isNewItem?: boolean
  ) => void
  activeStep: number
  setActiveStep: (newStep: number) => void
  hasActiveChanges: boolean
  setHasActiveChanges: (hasChanges: boolean) => void
  loading: boolean
}

export default function EditFormFeaturedResultSteps(
  props: IEditFormFeaturedResultStepsProps
): JSX.Element {
  const {
    item,
    isNewItem,
    saveItem,
    activeStep,
    setActiveStep,
    hasActiveChanges,
    setHasActiveChanges,
    loading
  } = props

  const rowData: FeaturedResult = item.itemData as FeaturedResult
  const orgRowData = useRef(Object.assign({}, rowData))

  const DOMPurify = createDOMPurify(window)
  const classes = getStylesRequestForm()
  const intl = useIntl()
  const topElement = useRef() as React.MutableRefObject<HTMLInputElement>

  // timezone into start date
  const parsedStartDate = rowData.BestBetStartDate
    ? new Date(rowData.BestBetStartDate)
    : null
  let startDateString
  if (parsedStartDate) {
    const timeOffsetInMS = parsedStartDate.getTimezoneOffset() * 60000
    parsedStartDate.setTime(parsedStartDate.getTime() - timeOffsetInMS)
    startDateString = parsedStartDate.toISOString()
  }
  // timezone into end date
  const parsedEndDate = rowData.BestBetEndDate
    ? new Date(rowData.BestBetEndDate)
    : null
  let endDateString
  if (parsedEndDate) {
    const timeOffsetInMS = parsedEndDate.getTimezoneOffset() * 60000
    parsedEndDate.setTime(parsedEndDate.getTime() - timeOffsetInMS)
    endDateString = parsedEndDate.toISOString()
  }

  const [startDate, setStartDate] = useState<Date | null | undefined>(
    startDateString ? new Date(startDateString) : null
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    endDateString ? new Date(endDateString) : null
  )

  const [previewItem, setPreviewItem] = useState(rowData)
  const [sources, setSources] = useState(
    rowData.BestBetDataSources.map((dsKey: string) => {
      return {
        key: capitalizeFirstLetter(dsKey),
        name: capitalizeFirstLetter(dsKey)
      }
    })
  )
  const [countries, setCountries] = useState(
    rowData.BestBetCountry && rowData.BestBetCountry.length > 0
      ? rowData.BestBetCountry
      : ['All']
  )
  const [functions, setFunctions] = useState(
    rowData.BestBetFunction && rowData.BestBetFunction.length > 0
      ? rowData.BestBetFunction
      : ['All']
  )
  const [matchType, setMatchType] = useState(
    rowData.BestBetMatchType ? rowData.BestBetMatchType : 'Contains'
  )
  const [scope, setScope] = useState(
    rowData && rowData.BestBetScope ? rowData.BestBetScope : 'All'
  )
  const [availableSources, setAvailableSources] = useState<
    ISupportedVertical[]
  >([])
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])
  const formState = useRef([
    { shouldValidate: false },
    { shouldValidate: false },
    { shouldValidate: false }
  ] as Array<IFormStep>)

  // used to trigger rerendering of input fields that are using default values
  // so the shown values are sanitized onBlur
  const [currentFormState, setCurrentFormState] = useState(new Date().getTime())
  const [countryList, setCountryList] = useState<string[]>([])
  const [functionList, setFunctionList] = useState<string[]>([])
  const [currentKeyWordToAdd, setCurrentKeyWordToAdd] = useState<string>('')
  const [selectedSearchTerms, setSelectedSearchTerms] = useState<
    Array<IKeyWordChipData>
  >(
    rowData.BestBetKeywords
      ? rowData.BestBetKeywords.map((label: string, index: number) => {
          return {
            key: index,
            label: label
          }
        })
      : []
  )
  const selectedTermsRef = useRef(selectedSearchTerms)
  const [showCommentDialog, setShowCommentDialog] = useState(false)
  const [blockSubmitOption, setBlockSubmitOption] = useState(true)

  useEffect(() => {
    if (isNewItem) {
      if (!endDate) {
        const dateString = createDefaultDate()
        setEndDate(new Date(dateString))
        rowData.BestBetEndDate = dateString
      }
      if (!startDate) {
        const dateString = new Date().toISOString()
        setStartDate(new Date(dateString))
        rowData.BestBetStartDate = dateString
      }
    } else {
      const isCompleteItemValid = validateCompleteItem()
      if (isCompleteItemValid) {
        setBlockSubmitOption(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateAvailableSources()

    if (scope === 'KPMGFind' && rowData.BestBetSiteUrl) {
      rowData.BestBetSiteUrl = ''
      validateItem()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope])

  /** Error Handling and Validation */
  const validateItem = (): boolean => {
    if (activeStep === 0) {
      setPreviewItem(Object.assign({}, rowData))
    }
    const hasChanges =
      JSON.stringify(rowData) !== JSON.stringify(orgRowData.current)
    setHasActiveChanges(hasChanges)

    if (formState.current[activeStep].shouldValidate) {
      let errorList: IErrorListObject[] = []

      errorList = [
        ...errorList,
        ...checkIsFeaturedResultFormStepValid(rowData, intl, activeStep)
      ]

      const isValid = !errorList.find(
        (error: IErrorListObject) => error.error.error
      )

      setErrorState(errorList)

      return isValid
    }

    return false
  }

  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const validateFieldAndUpdateError = (
    validateFunction: any,
    key: string,
    value: string | null,
    message: string
  ) => {
    const newError = validateFunction(value, key, message)

    if (newError && newError.length > 0) {
      const newErrorState = errorState.filter(
        (error: IErrorListObject) => error.key !== newError[0].key
      )
      newErrorState.push(newError[0])
      setErrorState(newErrorState)
    }
  }

  const validateCompleteItem = (): boolean => {
    let errorList: IErrorListObject[] = []

    errorList = [
      ...errorList,
      ...checkIsFeaturedResultFormStepValid(rowData, intl)
    ]

    const isValid = !errorList.find(
      (error: IErrorListObject) => error.error.error
    )
    return isValid
  }

  /** Handle intial states */
  const updateAvailableSources = () => {
    let functionList = supportedFunctions.map(
      (item: SupportedFunction) => item.name
    )
    let countryList = countriesISO.map((item) => item.Name)

    let sourcelist = supportedVerticals

    sourcelist = [{ key: 'All', name: 'All' }, ...sourcelist]
    countryList = ['All', ...countryList]
    functionList = ['All', ...functionList]

    setAvailableSources(sourcelist)
    setFunctionList(functionList)
    setCountryList(countryList)
  }

  /** Handle steps and submit */
  const handleNext = () => {
    formState.current[activeStep].shouldValidate = true
    const stepValid = validateItem()
    if (stepValid) {
      setActiveStep(activeStep + 1)
      topElement.current?.scrollIntoView({ block: 'nearest' })
    }
  }

  const handleSubmit = () => {
    formState.current[activeStep].shouldValidate = true
    const stepValid = validateItem()
    if (stepValid) {
      if (item.status !== RequestStatus.Clarification) {
        saveItem(RequestStatus.Submitted, null, orgRowData.current, isNewItem)
      } else {
        setShowCommentDialog(true)
      }
    }
  }

  const handleCommentClose = () => {
    setShowCommentDialog(false)
  }

  const handleCommentSubmit = (newComment: RequestComment | null) => {
    if (newComment) {
      updateComments(item, newComment)
    }

    saveItem(RequestStatus.Submitted, newComment, orgRowData.current, false)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
    topElement.current?.scrollIntoView({ block: 'nearest' })
  }

  /** handle keyWord Add and delete */
  const handleKeyWordDelete = (termToDelete: IKeyWordChipData) => {
    const newValue = selectedSearchTerms.filter(
      (chip) => chip.key !== termToDelete.key
    )
    setSelectedSearchTerms(newValue)

    rowData.BestBetKeywords = newValue.map(
      (chip: IKeyWordChipData) => chip.label
    )

    validateItem()
  }

  const handleKeyWordAdd = (keyWordToAdd: string) => {
    if (keyWordToAdd) {
      const newKeyWords = [
        ...selectedSearchTerms.map((chip: IKeyWordChipData) => {
          chip.showWarning = false
          return chip
        })
      ]

      const keywords = keyWordToAdd.split(',')
      let index = 1
      keywords.forEach((keyword: string) => {
        if (keyword && keyword.trim()) {
          const existingKeyWord = newKeyWords.find(
            (chipData: IKeyWordChipData) =>
              chipData.label.trim().toLowerCase() ===
              keyword.trim().toLowerCase()
          )

          if (!existingKeyWord) {
            newKeyWords.push({
              key: selectedSearchTerms.length + index,
              label: keyword.trim()
            })

            index = index + 1
          } else if (existingKeyWord.key <= selectedSearchTerms.length) {
            existingKeyWord.showWarning = true
          }
        }
      })

      if (
        selectedTermsRef.current.some(
          (chip: IKeyWordChipData) => chip.showWarning
        )
      ) {
        setTimeout(() => {
          setSelectedSearchTerms(
            selectedTermsRef.current.map((chip: IKeyWordChipData) => {
              chip.showWarning = false
              return chip
            })
          )
        }, 2000)
      }

      selectedTermsRef.current = newKeyWords
      setSelectedSearchTerms(newKeyWords)

      rowData.BestBetKeywords = newKeyWords.map(
        (chip: IKeyWordChipData) => chip.label
      )
    }
    validateItem()
  }

  return (
    <>
      <div ref={topElement} />
      {activeStep === 0 && (
        <div className={classes.formContainer}>
          <div className={classes.leftFormArea}>
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_headline',
                defaultMessage: 'Featured Headline'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_featured_headline_desc',
                defaultMessage:
                  'Provide an engaging title for the featured result'
              })}
              key={`title_${currentFormState}`}
              inputControl={
                <TextField
                  variant="outlined"
                  size="small"
                  defaultValue={rowData.BestBetTitle}
                  onChange={(event) => {
                    rowData.BestBetTitle = event.target.value
                      ? DOMPurify.sanitize(event.target.value, {
                          USE_PROFILES: { html: false }
                        })
                      : ''

                    validateItem()
                  }}
                  onBlur={() => {
                    setCurrentFormState(new Date().getTime())
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.form_field}
                  error={getError(editFormErrorKeys.title).error}
                />
              }
              hasError={getError(editFormErrorKeys.title).error}
              errorText={getError(editFormErrorKeys.title).helperText}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_url',
                defaultMessage: 'Featured URL'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_featured_url_desc',
                defaultMessage:
                  'Provide a URL to redirect users to relevant information when clicking on featured result Title'
              })}
              inputControl={
                <TextField
                  variant="outlined"
                  size="small"
                  defaultValue={rowData.BestBetUrl}
                  onChange={(event) => {
                    rowData.BestBetUrl = event.target.value
                      ? event.target.value.replace(/ /g, '%20')
                      : ''
                    validateItem()
                  }}
                  onBlur={(event) => {
                    validateFieldAndUpdateError(
                      validateUrlString,
                      editFormErrorKeys.url,
                      rowData.BestBetUrl,
                      intl.formatMessage({
                        id: 'form_validation_link_url',
                        defaultMessage: 'Please enter a valid link URL.'
                      })
                    )
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.form_field}
                  error={
                    getError(editFormErrorKeys.link).error ||
                    getError(editFormErrorKeys.url).error
                  }
                />
              }
              hasError={
                getError(editFormErrorKeys.link).error ||
                getError(editFormErrorKeys.url).error
              }
              errorText={
                getError(editFormErrorKeys.link).helperText ||
                getError(editFormErrorKeys.url).helperText
              }
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_body_text',
                defaultMessage: 'Featured Body Text'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_featured_body_text_desc',
                defaultMessage:
                  'Provide a descriptive text summarizing the details of configured featured result'
              })}
              key={`desc_${currentFormState}`}
              inputControl={
                <TextField
                  variant="outlined"
                  size="small"
                  defaultValue={rowData.BestBetDescription}
                  multiline
                  rows={5}
                  onChange={(event) => {
                    rowData.BestBetDescription = event.target.value
                      ? DOMPurify.sanitize(event.target.value, {
                          USE_PROFILES: { html: false }
                        })
                      : ''

                    validateItem()
                  }}
                  onBlur={() => {
                    setCurrentFormState(new Date().getTime())
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.form_field}
                  error={getError(editFormErrorKeys.description).error}
                />
              }
              hasError={getError(editFormErrorKeys.description).error}
              errorText={getError(editFormErrorKeys.description).helperText}
            />
          </div>
          <div className={classes.rightFormArea}>
            <div className={classes.rightFormTitle}>
              <FormattedMessage
                id="form_featured_step1_headline"
                defaultMessage="Request a Featured Result"
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_featured_step1_q1"
                  defaultMessage="What is a featured result?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_featured_step1_a1',
                      defaultMessage:
                        'A featured result is a highly-relevant piece of content displayed at top of the regular search results.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_featured_step1_q2"
                  defaultMessage="When to request a featured result?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_featured_step1_a2',
                      defaultMessage:
                        'Request a featured result when you want to promote or highlight a relevant piece of content, that is not displayed in the first 5 search results.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      {activeStep === 1 && (
        <div className={classes.formContainer}>
          <div className={classes.leftFormArea}>
            <div className={classes.singleLine}>
              <DefaultFormField
                label={intl.formatMessage({
                  id: 'form_featured_start',
                  defaultMessage: 'Start Date'
                })}
                required={true}
                description={intl.formatMessage({
                  id: 'form_featured_start_desc',
                  defaultMessage: 'Date when featured result starts appearing'
                })}
                inputControl={
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      value={startDate || null}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T00:00:00.000Z'
                        }

                        rowData.BestBetStartDate = dateString
                        setStartDate(date ? date : null)
                        validateItem()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true
                            }}
                            className={classes.form_field}
                            error={getError(editFormErrorKeys.startDate).error}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                }
                hasError={getError(editFormErrorKeys.startDate).error}
                errorText={getError(editFormErrorKeys.startDate).helperText}
              />
              <DefaultFormField
                label={intl.formatMessage({
                  id: 'form_featured_end',
                  defaultMessage: 'Expiration Date'
                })}
                required={true}
                description={intl.formatMessage({
                  id: 'form_featured_end_desc',
                  defaultMessage: 'Date when featured result stops appearing'
                })}
                inputControl={
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      value={endDate || null}
                      onChange={(date: Date | null) => {
                        let dateString = null
                        if (date && date.toString() !== 'Invalid Date') {
                          const day = date.getDate()
                          const month = date.getMonth() + 1
                          const year = date.getFullYear()
                          dateString =
                            year.toString() +
                            '-' +
                            month.toString().padStart(2, '0') +
                            '-' +
                            day.toString().padStart(2, '0') +
                            'T23:59:59.000Z'
                        }

                        rowData.BestBetEndDate = dateString
                        setEndDate(date ? date : null)
                        validateItem()
                      }}
                      onYearChange={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          const transformValue = (popperRoot[0] as HTMLElement)
                            .style.transform
                          setTimeout(() => {
                            ;(popperRoot[0] as HTMLElement).style.transform =
                              transformValue
                          }, 10)
                        }
                      }}
                      onClose={() => {
                        const popperRoot = document.querySelectorAll(
                          '[class*=MuiPickersPopper-root'
                        )
                        if (popperRoot.length > 0) {
                          ;(popperRoot[0] as HTMLElement).style.display = 'none'
                        }
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: classes.datePicker
                        }
                      }}
                      slots={{
                        textField: (textFieldProps) => (
                          <TextField
                            {...textFieldProps}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true
                            }}
                            className={classes.form_field}
                            error={getError(editFormErrorKeys.startDate).error}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                }
                hasError={getError(editFormErrorKeys.endDate).error}
                errorText={getError(editFormErrorKeys.endDate).helperText}
              />
            </div>
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_keyword',
                defaultMessage: 'List of Keywords'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_featured_keyword_desc',
                defaultMessage:
                  'A list of comma separated keywords that trigger the featured results. Example: "covid, covid-19, work from home"'
              })}
              inputControl={
                <div
                  className={`${classes.selectChipArea} ${
                    getError(editFormErrorKeys.searchTerms).error
                      ? classes.selectChipAreaError
                      : ''
                  }`}
                >
                  <div className={classes.selectChipInnerArea}>
                    <div>
                      <TextField
                        id="input-with-icon-textfield"
                        size="small"
                        value={currentKeyWordToAdd}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.addKeyWordFieldAddButton}
                              onClick={() => {
                                if (currentKeyWordToAdd) {
                                  handleKeyWordAdd(currentKeyWordToAdd)
                                  setCurrentKeyWordToAdd('')
                                }
                              }}
                            >
                              <AddCircle />
                            </InputAdornment>
                          )
                        }}
                        onChange={(event) => {
                          setCurrentKeyWordToAdd(
                            filterSearchTermInput(event.target.value)
                          )
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter' && currentKeyWordToAdd) {
                            handleKeyWordAdd(currentKeyWordToAdd)
                            setCurrentKeyWordToAdd('')
                          }
                        }}
                        variant="outlined"
                        className={classes.addKeyWordField}
                      />
                    </div>
                    {selectedSearchTerms.map((data: IKeyWordChipData) => {
                      return (
                        <Chip
                          label={data.label}
                          onDelete={() => handleKeyWordDelete(data)}
                          className={
                            data.showWarning
                              ? [
                                  classes.keyWordChip,
                                  classes.keyWordChipWarning
                                ].join(' ')
                              : classes.keyWordChip
                          }
                        />
                      )
                    })}
                  </div>
                </div>
              }
              hasError={getError(editFormErrorKeys.searchTerms).error}
              errorText={getError(editFormErrorKeys.searchTerms).helperText}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_matchtype',
                defaultMessage: 'Match Type'
              })}
              description={intl.formatMessage({
                id: 'form_featured_matchtype_desc',
                defaultMessage:
                  'Match type when comparing searched term against trigger keywords.<ul><li>Contains: Featured result is triggered if searched term contains one of the trigger keywords.</li><li>Exact: Featured result is triggered if searched term exactly matches one of the trigger keywords.</li><ul>'
              })}
              inputControl={renderSelect(
                matchType,
                setMatchType,
                ['Contains', 'Exact'],
                false,
                '',
                'BestBetMatchType',
                rowData,
                isNewItem,
                validateItem
              )}
            />
          </div>
          <div className={classes.rightFormArea}>
            <div className={classes.rightFormTitle}>
              <FormattedMessage
                id="form_featured_step2_headline"
                defaultMessage="Request a Featured Result"
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_featured_step2_q1"
                  defaultMessage="What is the lifecycle of a featured result?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_featured_step2_a1',
                      defaultMessage:
                        'Once the request is submitted, we review the request and it goes LIVE if no clarifications are needed. Once LIVE, it is triggered for the specified search terms, taking into account other trigger criterias like Country, Function etc.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_featured_step2_q2"
                  defaultMessage="When are featured results visible to the end users?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_featured_step2_a2',
                      defaultMessage:
                        'When the specified search criteria including search term, country, vertical etc. is met, featured result is triggered and displayed to the end user.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      {activeStep === 2 && (
        <div className={classes.formContainer}>
          <div className={classes.leftFormArea}>
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_scope',
                defaultMessage: 'Application Scope'
              })}
              description={intl.formatMessage({
                id: 'form_featured_scope_desc',
                defaultMessage:
                  'Choose one or both of Enterprise Search applications (KPMG Find, One Intranet Search) where featured result will be displayed.'
              })}
              inputControl={renderSelect(
                scope,
                setScope,
                ['OI', 'KPMGFind', 'All'],
                false,
                '',
                'BestBetScope',
                rowData,
                isNewItem,
                validateItem
              )}
            />
            {(scope === 'OI' || scope === 'All') && (
              <DefaultFormField
                label={intl.formatMessage({
                  id: 'form_featured_memberurl',
                  defaultMessage: 'Site URL'
                })}
                description={intl.formatMessage({
                  id: 'form_featured_memberurl_desc',
                  defaultMessage:
                    'Optionally restrict the Featured Result to a particular site URL'
                })}
                inputControl={
                  <TextField
                    variant="outlined"
                    id="site_url"
                    size="small"
                    defaultValue={rowData.BestBetSiteUrl}
                    onChange={(event) => {
                      rowData.BestBetSiteUrl = event.target.value
                        ? event.target.value.replace(/ /g, '%20')
                        : event.target.value
                      validateItem()
                    }}
                    onBlur={(event) => {
                      validateFieldAndUpdateError(
                        validateUrlString,
                        editFormErrorKeys.site_url,
                        rowData.BestBetSiteUrl,
                        intl.formatMessage({
                          id: 'form_validation_siteurl',
                          defaultMessage:
                            'Please enter a valid OneIntranet site url.'
                        })
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    className={classes.form_field}
                    error={getError(editFormErrorKeys.site_url).error}
                  />
                }
                hasError={getError(editFormErrorKeys.site_url).error}
                errorText={getError(editFormErrorKeys.site_url).helperText}
              />
            )}
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_datasources',
                defaultMessage: 'Search Verticals'
              })}
              required={true}
              description={intl.formatMessage({
                id: 'form_featured_datasources_desc',
                defaultMessage:
                  'A list of comma separated search verticals where featured result will be displayed. <b>Default-All</b>'
              })}
              inputControl={renderSelect(
                sources,
                setSources,
                availableSources,
                true,
                '',
                'BestBetDataSources',
                rowData,
                isNewItem,
                validateItem,
                false,
                {
                  error: getError(editFormErrorKeys.source).error,
                  helperText: ''
                }
              )}
              hasError={getError(editFormErrorKeys.source).error}
              errorText={getError(editFormErrorKeys.source).helperText}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_countries',
                defaultMessage: 'List of Countries'
              })}
              description={intl.formatMessage({
                id: 'form_featured_countries_desc',
                defaultMessage:
                  'A list of comma separated countries where featured result will be displayed. <b>Default-All</b>'
              })}
              inputControl={renderSelect(
                countries,
                setCountries,
                countryList,
                true,
                '',
                'BestBetCountry',
                rowData,
                isNewItem,
                validateItem
              )}
            />
            <DefaultFormField
              label={intl.formatMessage({
                id: 'form_featured_functions',
                defaultMessage: 'List of Functions'
              })}
              description={intl.formatMessage({
                id: 'form_featured_functions_desc',
                defaultMessage:
                  'A list of comma separated functions where featured result will be displayed. <b>Default-All</b>'
              })}
              inputControl={renderSelect(
                functions,
                setFunctions,
                functionList,
                true,
                '',
                'BestBetFunction',
                rowData,
                isNewItem,
                validateItem
              )}
            />
          </div>
          <div className={classes.rightFormArea}>
            <div className={classes.rightFormTitle}>
              <FormattedMessage
                id="form_featured_step3_headline"
                defaultMessage="Request a Featured Result"
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_featured_step3_q1"
                  defaultMessage="How can I control the reach of my featured result?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_featured_step3_a1',
                      defaultMessage:
                        'There are multiple options including Search Term, Country, Function, Scope etc. that can be used to control the reach, and details for each of them are provided with the field name.'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
            <div className={classes.questionArea}>
              <div className={classes.questionTitle}>
                <FormattedMessage
                  id="form_featured_step3_q2"
                  defaultMessage="Are there any metrics available for featured result usage?"
                />
              </div>
              <div
                className={classes.questionAnswer}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    intl.formatMessage({
                      id: 'form_featured_step3_a2',
                      defaultMessage:
                        'We have featured result usage metrics available as part of Enterprise Search metrics dashboard, that can be accessed here: <a href="https://app.powerbi.com/groups/e222ecc1-1a00-44b8-ba77-3e1c524f8e18/reports/40f33a4b-d30b-4566-baca-3f18ba012e1d/ReportSection8dae7197a0d1245c125e?experience=power-bi&chromeless=1" target="_blank">Link to usage metrics</a>'
                    }),
                    {
                      USE_PROFILES: { html: true },
                      ADD_ATTR: ['target']
                    }
                  )
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={classes.previewArea}>
        <div className={classes.previewHeader}>
          <FormattedMessage id="form_label_preview" defaultMessage="Preview:" />
        </div>
        <div className={classes.preview}>
          <FeaturedResultPreview result={previewItem} />
        </div>
      </div>
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={activeStep}
        nextButton={
          <>
            <div>
              <Button onClick={handleBack} disabled={activeStep === 0}>
                <FormattedMessage id="form_button_back" defaultMessage="Back" />
              </Button>
              <Button
                onClick={handleNext}
                style={{ marginLeft: '10px' }}
                disabled={activeStep === 2}
              >
                <FormattedMessage id="form_button_next" defaultMessage="Next" />
              </Button>
              {(activeStep === 2 || !blockSubmitOption) && (
                <Tooltip
                  title={
                    item.status === RequestStatus.Declined
                      ? intl.formatMessage({
                          id: 'form_featured_declined_tooltip',
                          defaultMessage:
                            'The requested featured result got declined, no changes can be submitted.'
                        })
                      : !hasActiveChanges &&
                        item.status !== RequestStatus.Clarification
                      ? intl.formatMessage({
                          id: 'form_featured_nochange_tooltip',
                          defaultMessage:
                            "The feature result request didn't have any changes."
                        })
                      : ''
                  }
                  placement={'top'}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                      disabled={
                        loading ||
                        item.status === RequestStatus.Declined ||
                        (!hasActiveChanges &&
                          item.status !== RequestStatus.Clarification &&
                          item.status !== RequestStatus.Draft)
                      }
                      style={{ marginLeft: '10px' }}
                    >
                      {item.status !== RequestStatus.Clarification ? (
                        <FormattedMessage
                          id="form_button_submit"
                          defaultMessage="Submit Request"
                        />
                      ) : (
                        <FormattedMessage
                          id="form_button_resolve_clarification"
                          defaultMessage="Resolve Clarification"
                        />
                      )}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </div>
          </>
        }
        backButton={
          <div style={{ width: '50%' }}>
            {!item.oncePublished && (
              <Button
                onClick={() => saveItem()}
                disabled={loading || item.status === RequestStatus.Declined}
              >
                <FormattedMessage
                  id="form_button_save"
                  defaultMessage="Save as Draft"
                />
              </Button>
            )}
          </div>
        }
      />
      {showCommentDialog && (
        <EditFormCommentDialog
          item={item}
          showCommentDialog={showCommentDialog}
          closeCommentDialog={handleCommentClose}
          submitNewComment={handleCommentSubmit}
          hasActiveChanges={hasActiveChanges}
        />
      )}
    </>
  )
}
