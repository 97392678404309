import {
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'
import {
  editFormErrorKeys,
  checkIsValidNewsLetterSubscription
} from 'utils/admin/adminFormUtils'
import { IErrorListObject, IErrorObject } from 'models/FormError'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import { supportedMemberFirms } from 'constants/supportedMemberFirms'
import DefaultFormField from 'components/contents/common/DefaultFormField'
import { IPeopleResult } from 'models/People'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import PeopleSearch from 'components/contents/common/PeopleSearch'
import { Store } from 'store'
import { connect } from 'react-redux'
import AuthStore from 'store/Auth'

export interface IEditFormSubscriptionProps {
  item: INewsLetterSubscription
  setIsValid: (isValid: boolean) => void
}

type IAllEditFormSubscriptionProps = IEditFormSubscriptionProps &
  ReturnType<typeof mapStateToProps>

function EditFormSubscription(
  props: IAllEditFormSubscriptionProps
): JSX.Element {
  const { item, setIsValid, aadInfo } = props
  const [loaded, setLoaded] = useState(false)
  const classes = getStylesAdminForm()
  const [errorState, setErrorState] = useState<IErrorListObject[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Array<IPeopleResult>>([])
  const [showPeoplePicker, setShowPeoplePicker] = useState(false)

  useEffect(() => {
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const selectedUsersRef = useRef(selectedUsers)

  ///error
  const getError = (key: string): IErrorObject => {
    const errorItem = errorState.find(
      (errorItem: IErrorListObject) =>
        errorItem.error.error && errorItem.key === key
    )

    if (errorItem) return errorItem.error

    return {
      error: false,
      helperText: ''
    }
  }

  const validateItem = (): void => {
    console.log('###', item)
    let errorList: IErrorListObject[] = checkIsValidNewsLetterSubscription(item)

    setIsValid(!errorList.find((error: IErrorListObject) => error.error.error))
    setErrorState(errorList)
  }

  /** handle keyWord Add and delete */
  const handleUserDelete = (userToDelete: IPeopleResult) => {
    const newValue = selectedUsers.filter(
      (user: IPeopleResult) =>
        user.userPrincipalName !== userToDelete.userPrincipalName
    )
    setSelectedUsers(newValue)

    item.upn = newValue
      .map((user: IPeopleResult) => user.userPrincipalName)
      .join(',')

    validateItem()
  }

  const handleUserAdd = (newUser: IPeopleResult) => {
    if (newUser) {
      const newUsers = [
        ...selectedUsers.map((user: IPeopleResult) => {
          user.showWarning = false
          return user
        })
      ]

      const existingUser = newUsers.find(
        (chipData: IPeopleResult) =>
          chipData.userPrincipalName === newUser.userPrincipalName
      )

      if (!existingUser) {
        newUsers.push(newUser)
      } else {
        existingUser.showWarning = true
      }

      if (
        selectedUsersRef.current.some((user: IPeopleResult) => user.showWarning)
      ) {
        setTimeout(() => {
          setSelectedUsers(
            selectedUsersRef.current.map((user: IPeopleResult) => {
              user.showWarning = false
              return user
            })
          )
        }, 2000)
      }

      selectedUsersRef.current = newUsers
      setSelectedUsers(newUsers)

      item.upn = newUsers
        .map((user: IPeopleResult) => user.userPrincipalName)
        .join(',')
    }
    validateItem()
    setShowPeoplePicker(false)
  }

  return (
    <>
      {item && loaded && (
        <>
          <DefaultFormField
            label={"List of Email ID's"}
            required={true}
            description={''}
            inputControl={
              <div
                className={`${classes.selectChipArea} ${
                  getError(editFormErrorKeys.upn).error
                    ? classes.selectChipAreaError
                    : ''
                }`}
                style={{ position: 'relative' }}
              >
                <div style={{ position: 'absolute', right: '2px', top: '2px' }}>
                  <IconButton
                    size="medium"
                    aria-label="close"
                    color="primary"
                    onClick={() => {
                      setShowPeoplePicker(true)
                    }}
                    title={'Search and Add user'}
                  >
                    <PersonSearchIcon fontSize="medium" />
                  </IconButton>
                </div>
                <div className={classes.selectChipInnerArea}>
                  {selectedUsers.map((data: IPeopleResult, index: number) => {
                    return (
                      <Chip
                        key={`userchip${index}`}
                        label={data.displayName}
                        onDelete={() => handleUserDelete(data)}
                        className={
                          data.showWarning
                            ? [
                                classes.keyWordChip,
                                classes.keyWordChipWarning
                              ].join(' ')
                            : classes.keyWordChip
                        }
                      />
                    )
                  })}
                </div>
              </div>
            }
            hasError={getError(editFormErrorKeys.upn).error}
            errorText={getError(editFormErrorKeys.upn).helperText}
          />
          {showPeoplePicker && (
            <PeopleSearch
              aadInfo={aadInfo}
              handleClose={() => {
                setShowPeoplePicker(false)
              }}
              addUser={handleUserAdd}
              forcedTitle={'Select user'}
              forcedAddButtonLabel={'Select'}
            />
          )}
          <FormControl style={{ marginTop: '10px' }} fullWidth size="small">
            <InputLabel id="select-label">Member firm *</InputLabel>
            <Select
              id="memberfirm-required"
              multiple={false}
              variant={'outlined'}
              defaultValue={item.memberFirm}
              size="small"
              label="Member firm *"
              onChange={(event: SelectChangeEvent<string>) => {
                item.memberFirm = event.target.value
              }}
            >
              {supportedMemberFirms.map((key: string) => {
                return (
                  <MenuItem key={key} value={key}>
                    <ListItemText primary={key} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps, null)(EditFormSubscription)
